
import React, {Component} from 'react';
import Session from '../Services/Session';
import Api from '../Services/Api';
import Paginate from '../Layout/Components/Paginate'

import  InputFileImg from '../Layout/Components/InputFileImg'
import { Spinner } from 'reactstrap'
import FormProcessing from '../Services/FormProcessing'
import { DateTime } from 'react-datetime-bootstrap';
import UserImg from '../Layout/Assets/user.png';
import { CSVLink, CSVDownload } from "react-csv";

export default class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			counters: [
				{
					title:'Usuarios',
					value:'0'
				},
				{
					title:'Broze',
					value:'0'
				},
				{
					title:'Silver',
					value:'0'
				},
				{
					title:'Golden',
					value:'0'
				},
				{
					title:'Total de Votos',
					value:'0'
				},
				,
			],
			last: [],
			events:[],
			csvData:[],
			headers:[
			  	{ label: "Voto id", key:"voto_id" },
				{ label: "Proveedor", key:"proveedor" },
				{ label: "Producto", key:"producto" },
				{ label: "Correo", key:"email" },
				{ label: "Valoración", key:"valoracion" },
				{ label: "Numero Boleta", key:"numero_boleta" },
				{ label: "Comentario", key:"comentario" },
				{ label: "Url foto", key:"foto" },
				{ label: "Hora de voto CL", key:"hora_de_votacion_santiago" },
				{ label: "Ip", key:"ip" },
				{ label: "Dispositivo", key:"dispositivo" },
				{ label: "País", key:"country" },
			],
			pagination:null,
		};
		this.getDetails = this.getDetails.bind(this);
		this.listVoto = this.listVoto.bind(this);
	}
	UNSAFE_componentWillMount() {
		window.scrollTo(0,0)
		this.getDetails()
	}
	async getDetails(page={}){
		let response = await Api.adminDashboard(page)
		const pagination = {
			last_page:response.last_page,
			current_page:response.current_page,
			per_page:response.per_page
		}
		this.state.counters[4].value=response.total_votings.cantidad;
		this.state.counters[1].value=response.bronze.cantidad;
		this.state.counters[2].value=response.silver.cantidad;
		this.state.counters[3].value=response.gold.cantidad;
		this.state.counters[0].value=response.users.cantidad;
		this.state.last=response.last.data;
		this.setState({
			events:response.data,
			pagination,
			modal:{
				toggle:false
			},
			counters:this.state.counters,
			last:this.state.last
		});
	}
	async listVoto(event_id=null){
		let response = await Api.listVoto({event_id})
		if(response?.error){
			alert("evento no encontrado")
		}
		this.setState({
			csvData:response,
		})
		this.setState({
			csvData:[],
		})
	}
	
	render() {
		const { counters, last, events, csvData, headers, pagination} = this.state
		const { listVoto } = this
		return(
			<div className="row">
				{ csvData.length>0 ? <CSVDownload data={csvData} headers={headers} target="_blank" filename={`lista_votos.csv`} /> : null}
				<div className="col">
					<h2 className="mt-3 px-3">Dashboard</h2>
				</div>
				<div className="col-auto pt-3 text-right">
					<div class="btn-group" role="group" aria-label="...">
						<button className="btn btn-primary" type="submit" onClick={()=>listVoto()}>
							Desrcargar votos
						</button>
						<button className="btn btn-primary" type="submit" onClick={()=>listVoto()}>
							Desrcargar usuarios
						</button>
					</div>
				</div>
				<div className="col-12">
					<hr/>
				</div>
				<div className="col-12 col-md-3">
					<ul className="list-group">
						{	counters.length > 0 ? 
							counters.map(function callback(counter, index, array) {
							    return(
							    	<li className="list-group-item text-center">
										<h4 className="my-0">{counter.title}</h4>
										<h5 className="my-0">{counter.value}</h5>
									</li>
							   	)
							})
							:
							<li className="list-group-item text-center">
								<h4 className="my-0">Sin parametros</h4>
							</li>
						}
						
					</ul>
				</div>
				<div className="col-12 col-md-6">
					<table className="table table-bordered table-hover">
						<thead>
							<tr>
								<th scope="col" colspan="6" className="text-center">
									Eventos
								</th>
							</tr>
						</thead>
						<thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Nombre/Duración</th>
								<th scope="col">Votos</th>
								<th scope="col">Nuevos</th>
								<th scope="col">Votantes</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody>
							{	events.length > 0 ? 
								events.map(function callback(event, index, array) {
								    return(
										<tr  valign="middle">
											<th scope="row">{event.id}</th>
											<td>
												{event.name}
												<br/>
												<small><small>incio: {event.start}</small></small>
												<br/>
												<small><small>fin: {event.end}</small></small>
												<br/>
											</td>
											<td>{event.votos.cantidad}</td>
											<td>{event.nuevos.cantidad}</td>
											<td>{event.votantes.cantidad}</td>
											<td>
												<button className="btn btn-primary btn-sm" type="submit" onClick={()=>listVoto(event.id)}>
													votos
												</button>
											</td>
										</tr>
								   	)
								})
								:
								<tr>
									<th scope="row" colspan="6">Sin eventos</th>
								</tr>
							}
						</tbody>
					</table>
				</div>
				<div className="col-12 d-flex justify-content-center fixed-bottom pb-5"
						style={{
							marginBottom: '5rem',
						}}>
					{
						pagination != null ?
						<Paginate 
							last_page={pagination.last_page}
							current_page={pagination.current_page} 
							per_page={pagination.per_page}
							loadList={this.getDetails}
							classNamew="border border-bottom-0 bg-white px-5 pt-4"
						/>
						:null
					}
				</div>
				<div className="col-12 col-md-3">
					<ul className="list-group">
						<li className="list-group-item text-center">
							<h4 className="my-0">Ultimos 5 votos</h4>
						</li>
						{	last.length > 0 ? 
							last.map(function callback(data, index, array) {
							    return(
							    	<li className="list-group-item">
							    		
										<div className="row">
										<div className="col-12">
											<stronge className="my-0 float-left small">{data.created_at}</stronge>
											<stronge className="my-0 float-right small">{data.user.username}</stronge>
										</div>
										<div className="col-3 pt-2">
											<img src={data.photo_url} className="float-left img-fluid"/>
										</div>
										<div className="col-9">
										<p className="small">
											{data.description}
										</p>
										</div>
										</div>
									</li>
							   	)
							})
							:
							<li className="list-group-item text-center">
								<h4 className="my-0">Sin ultimo votos</h4>
							</li>
						}
						
					</ul>
				</div>
			</div>
		);
	}
}