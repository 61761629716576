import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router,useLocation, Route, Redirect, Switch} from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import AddToHomescreen from 'react-add-to-homescreen';

import Session from './Services/Session'
import Users from './Layout/Users'
import UserLayout from './Layout/Users/UserLayout'
import View404 from './Layout/View404';

import RouteVoteHere from './VoteHere/RouteVoteHere'
import VotingProduct from './VoteHere/VotingProduct'
import Restaurant from './VoteHere/Restaurant'
import Suppliers from './VoteHere/Suppliers'

import EventsPublic from './VoteHere/EventsPublic'
import Product from './VoteHere/Product'
import Product2 from './VoteHere/Product2'
import TheTop from './VoteHere/TheTop'
import TheTopJuez from './VoteHere/TheTopJuez'
import TheTopReviews from './VoteHere/TheTopReviews'

import Juez from './VoteHere/Juez'
import TodosLosJuez from './VoteHere/TodosLosJuez'

import Locations from './VoteHere/Locations'
import Category from './VoteHere/Category'
import Profile from './User/Profile'
import FormEdit from './User/FormEdit'
import ListUser from './User/List'


import RouteAuth from './Auth/RouteAuth'
import SignIn  from './Auth/SignIn';
import SignUp  from './Auth/SignUp';
import ResetPassword  from './Auth/ResetPassword';
import ActivateAccount  from './Auth/ActivateAccount';
import SentEmailChangePassword  from './Auth/SentEmailChangePassword';

import Dashboard from './Admin/Dashboard'
import Products from './Admin/Products'
import Categories from './Admin/Categories'
import Providers from './Admin/Providers'
import Events from './Admin/Events'
import ScrollToTop from './Components/Scroll/ScrollToTop';

import 'bootstrap/dist/css/bootstrap.min.css'
import './Css/main.scss'
import ReactGA from 'react-ga'

import TagManager from 'react-gtm-module'
import 'rsuite/dist/rsuite.min.css';
const tagManagerArgs = {
    gtmId: 'GTM-P8D2H2R',
    dataLayer: {
        userId: Session.getUserName(),
        userProject: 'project'
    }
}
 
TagManager.initialize(tagManagerArgs)

function App() {
	//usePageViews();
	return  Session.isAuth() ?
		(	Session.isAdmin() ? 
			<Users>
				<Switch>
					<Redirect from='/frontend/build' to='/' />
					<Redirect from="/sign/in" to={'/'}/>
					<Redirect from="/admin/sign/in" to={'/'}/>
					<Route exact path={["/vota-aca/","/"]} render={(props) => <Dashboard {...props}/>}/>
					<Route exact path={"/admin/productos"} render={(props) => <Products {...props}/>}/>
					<Route exact path={"/admin/categorias"} render={(props) => <Categories {...props}/>}/>
					<Route exact path={"/admin/proveedores"} render={(props) => <Providers {...props}/>}/>
					<Route exact path={"/admin/eventos"} render={(props) => <Events {...props}/>}/>
					<Route component={View404}/>
				</Switch>
			</Users>
			:
			<UserLayout>
				<Switch>
					<Redirect from='/frontend/build' to='/' />
					<Redirect from="/sign/in" to={'/'}/>
					<Route exact path="/perfil/editar" component={FormEdit}/>
					<Route exact path="/mi/perfil" render={(props) => <Profile {...props}/>}/>
					<Route exact path="/mi/perfil/:user_name" render={(props) => <Profile {...props}/>}/>
					<Route exact path="/perfil/:user_name" render={(props) => <Profile {...props}/>}/>
					<Route exact path="/categorias" component={Category}/>
					{/* <Route exact path="/juez" component={Juez}/> */}
					<Route exact path="/juez" component={TheTopJuez}/>
					<Route exact path="/reviews" component={TheTopReviews}/>
          
					<Route exact path="/todos/juez" component={TodosLosJuez}/>
					<Route exact path="/juez/votacion/:id"render={(props) => <VotingProduct {...props}/>}/>
					<Route exact path="/juez/buscar" component={ListUser}/>
					<Route exact path="/seguidos/por/:user_name" render={(props) => <ListUser {...props} typeSearch="followed"/>}/>
					<Route exact path="/seguidores/de/:user_name" render={(props) => <ListUser {...props}  typeSearch="followers"/>}/>
					<Route exact path="/juez/:user_name" component={Profile}/>
					<Route exact path={"/the-top/zona/:zona"} render={(props) => <TheTop {...props}/>}/>
					<Route exact path="/burger/:slug" render={(props) => <Product {...props}/>}/>

					<Route exact path="/producto/:slug" render={(props) => <Product2 {...props}/>}/>

					<Route exact path="/voting/burger/:slug" render={(props) => <VotingProduct {...props}/>}/>
					<Route exact path="/proveedor/:slug" render={(props) => <Suppliers {...props}/>}/>
					<Route exact path="/evento/:id" render={(props) => <EventsPublic {...props}/>}/>
					<Route exact path="/restaurante/:slug" render={(props) => <Restaurant {...props}/>}/>
					<Route exact path="/restoranes/zona/:zona" render={(props) => <RouteVoteHere {...props}/>}/>
					<Route exact path={["/vota-aca/","/"]} render={(props) => <Locations {...props}/>}/>
					<Route component={View404}/>
				</Switch>
			</UserLayout>
		)
		:
		<>
		<Switch>
			{/*<Route strict path="/the-top" component={(props) => <TheTop {...props}/>}/>*/}
			<Redirect strict exact from='/frontend/build' to='/' />
			<Route exact path={'/activar/cuenta/:token'} render={(props) => <ActivateAccount {...props}/>}/>
			<Route exact path={'/reset/:token'} render={(props) => <ResetPassword {...props}/>}/>
			<Route exact path={'/sign/in'} component={SignIn}/>
			<Route exact path={'/sign/up'} component={SignUp}/>
			<Route exact path={'/enviar/mail'} component={SentEmailChangePassword}/>

			<Redirect strict exact from='/admin' to='/admin/sign/in' />
			<Route exact path={'/admin/sign/in'} render={(props) => <SignIn {...props} profile='admin'/>}/>

			<Route render={(props) =>
				{
					return(
						<UserLayout>
						<Switch>
							<Route exact path="/categorias" component={Category}/>
							{/* <Route exact path="/juez" component={Juez}/> */}

              <Route exact path="/juez" component={TheTopJuez}/>
					    <Route exact path="/reviews" component={TheTopReviews}/>
              <Route exact path="/proveedor/:slug" render={(props) => <Suppliers {...props}/>}/>
					    <Route exact path="/evento/:id" render={(props) => <EventsPublic {...props}/>}/>
              <Route exact path="/producto/:slug" render={(props) => <Product2 {...props}/>}/>

							<Route exact path="/todos/juez" component={TodosLosJuez}/>
							<Route exact path="/juez/buscar" component={ListUser}/>
							<Route strict path="/the-top" component={(props) => <TheTop {...props}/>}/>
							<Route exact path="/restaurante/:slug" render={(props) => <Restaurant {...props}/>}/>
							<Route exact path="/restoranes/zona/:zona" render={(props) => <RouteVoteHere {...props}/>}/>
							<Route exact path={["/vota-aca/","/"]} render={(props) => <Locations {...props}/>}/>
							<Route exact path="/juez/:user_name" component={Profile}/>
							<Route exact path="/perfil/:user_name" render={(props) => <Profile {...props}/>}/>
							<Route exact path="/seguidos/por/:user_name" render={(props) => <ListUser {...props} typeSearch="followed"/>}/>
							<Route exact path="/seguidores/de/:user_name" render={(props) => <ListUser {...props}  typeSearch="followers"/>}/>
							<Route exact path="/juez/votacion/:id"render={(props) => <VotingProduct {...props}/>}/>
							<Redirect from={'/'} to={'/sign/in'}/>
							<Route component={View404}/>
						</Switch>
						</UserLayout>
					)
					
				}
			}/>
		</Switch>	
		</>
	;
}

ReactDOM.render(
	<>
	<Router>
    <ScrollToTop/>
		<App />
	</Router>
	<div style={{
		position: 'fixed',
		top: '0px',
		left: '0px',
		padding: '0px 5px',
		background: '#00000017',
		fontSize: '10px',
		zIndex:'99999999'
	}}>
		<small>Version:1.4.9</small>
	</div>
	</>,
  document.getElementById('root')
);
serviceWorker.register({
    onUpdate: registration => {
      alert('New version available!  Ready to update?');
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
      window.location.reload();
    }
  })