import React from 'react';


import MainAutoComplete from '../Components/Searchs/MainAutoComplete'

import MainQuantities from '../Components/Stats/MainQuantities';
import WidgetSuppliers from '../Components/Widgets/WidgetSuppliers';
import WidgetCategory from '../Components/Widgets/WidgetCategory';
import WidgetReviews from '../Components/Widgets/WidgetReviews';
import WidgetCommunity from '../Components/Widgets/WidgetCommunity';

import BannerReviewsJpg from '../Layout/Assets/BannerReviews.jpg'
import BannerReviewPng from '../Layout/Assets/banner_review.png'



const TheTopReviews = () => {
  return(
    <>
    <div className="row text-white py-5 position-relative banner-reviews align-items-center">
      <img src={BannerReviewPng} alt=""  className="banner-reviews-img"
        />
      <div className="col-auto py-4">
        <h1>REVIEWS</h1>
        <p className="text-right">COMIENDO, CLASIFICANDO Y COMPARTIENDO</p>
      </div>
    </div>
    <MainQuantities/>
    <div className="row pt-4">
      <div className="col-12 pb-3">
        <MainAutoComplete placeholder="BUSCAR UN RESTAURANT/MARCA"/>
      </div>
    </div>
    <WidgetCategory elementClassName='col-6 col-md-3'/>
    <WidgetSuppliers/>
    <WidgetReviews/>
    <WidgetCommunity slider={true} amount={10}/>
    </>
  )
}

export default TheTopReviews