import  React, { Component } from 'react';
import { Link } from 'react-router-dom';

//import the_top_sushi_blanco from '../Layout/Assets/the_top_sushi_blanco-1024x271.png';
//import placeholder_restaurant from '../Layout/Assets/placeholder-restaurant.jpg';

import chop from '../Layout/Assets/chop.gif';
//import Paginate from '../Layout/Components/Paginate'
import Api from '../Services/Api'
import { GetForCountry }from '../Services/Helpers';
import Countdown from "react-countdown";
import  { ReactComponent as Start } from  '../Layout/Assets/boton_estrella-01.svg'

class TheTop extends Component {
	constructor(props) {
		super(props)
		this.state = {
			restaurants: false,
			contar: false,
			dateStart: Date.now(),
			dateEnd: Date.now(),
			loading:true,
			finalizar:false,
		}
		this.loadList = this.loadList.bind(this)
		this.getComplete = this.getComplete.bind(this)
		this.getFinalizar = this.getFinalizar.bind(this)
	}
	componentDidMount() {
		window.scrollTo(0,0)
		this.loadList()
	}
	async loadList(page={}){
		let response = await Api.theTop(page)
		var pages = {
			last_page:response.last_page,
			current_page:response.current_page,
			per_page:response.per_page
		}
		let dateStart=(new Date(response.event.start.replace(' ', 'T'))).getTime();
		let dateEnd=(new Date(response.event.end.replace(' ', 'T'))).getTime();

		//console.log('data',dateEnd)
		this.setState({
			restaurants: response.data,
			dateStart: dateStart,
			dateEnd: dateEnd,
			loading: false,
			contar: (this.state.dateStart < dateStart),
			finalizar:(this.state.dateEnd > dateEnd),
		})
	}
	getComplete(){
		this.setState({
			contar: false
		})
	}
	getFinalizar(){
		this.setState({
			finalizar: true
		})
	}
	render(){
		let {restaurants, loading, contar, dateStart, dateEnd,finalizar} = this.state
		const {country,alfa2} = GetForCountry()
		//console.log(typeof restaurants === 'object')
		// if (alfa2==='CL') {
		// 	return (
		// 		<>
		// 			<h3 className="text-center text-danger mb-3">
		// 				El evento finalizado
		// 			</h3>
		// 		</>
		// 	);
		// }
		if((typeof restaurants === 'object' || restaurants===false) && contar===true){
			return(
				<div className="text-center contador-style">
					<h4>
						El evento pronto iniciará
					</h4>
					<Countdown date={dateStart} onComplete={()=>this.getComplete()}/>
					
				</div>
			)
		}
		if((typeof restaurants === 'object' || restaurants===false) && finalizar===true){
			return(
				<div className="text-center contador-style">
					<h3>
						El evento finalizó
					</h3>
				</div>
			)
		}
		return (
			<>
				<h3 className="text-center text-danger mb-3">
					The Top Puntuaciones
				</h3>
				<p className="text-center">
					el evento terminara en:
					<br/>
					<Countdown date={dateEnd} onComplete={()=>this.getFinalizar()}/>
				</p>
				<div className="row justify-content-center d-flex align-items-stretch px-1">
					{
						typeof restaurants === 'object' ?
							restaurants.map(function(r, i){
								let raring=r.rating_final
								raring = raring===null ? 0 : raring
								let puntuacion=parseFloat(raring)
								let percentage=((parseFloat(raring)*100)/5)+'%'
								let leftEmpty = raring===0 ? {left:'0px',position:'relative'} : {}
								
								//console.log(percentage)
								/*return (
									<div className="col-4 col-md-4 col-lg-3 px-1 mb-2" key={"r-"+i}>
										<Link to={"/burger/"+r.slug} className="h-100">									
											<div className="border mb-5 text-dark h-100 text-center" style={{position:"relative"}}>
												<img alt="" className="img-fluid w-75 py-5" src={r.logo_url}/>
												<img 
													alt=""
													className="img-fluid"
													src={r.logo_url_restaurant}
													style={{
														// "position": "absolute",
														// "width": "50px",
														// "left": "0px",
														// "background":"white",
														// "top": "0px",
														// "border": "0px solid #dee2e6"

														"position": "absolute",
														"width": "65px",
														"left": "6px",
														"background":"white none repeat scroll 0% 0%",
														"top": "6px",
														"border": "1px solid  #dee2e6",
														"border-radius": "50%",
														"padding": "5px",
													}}/>

												<small className="d-block">{puntuacion}</small>
												<div className="star-ratings"  style={{ width: percentage}}>
													<div className="fill-ratings" style={{ width: percentage}}>
														<div>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
														</div>
													</div>
													<div className="empty-ratings" style={leftEmpty}>
														<div>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
														</div>
													</div>
												</div>
												<small>votos: {r.total_votos}</small>
												<p className={"px-3 font-weight-bold res-id-"+r.id}>
													<small>{r.name}</small>
												</p>
												{\/*<p className="px-4 text-center">
													<small>{r.address}, &nbsp;
													{r.commune.name}</small>
												</p>*\/}
											</div>
										</Link>
									</div>
								)*/

								return (
									<div className="col-12 px-0 col-md-6 px-md-2 mb-3" key={"r-"+i}>
									<Link to={"/burger/"+r.slug} className="h-100 card" >
										<div className="row no-gutters text-dark d-flex align-items-stretch h-100">
											<div className="col-4">
												<img alt={"img-"+r.slug} className="card-img h-100" src={r.logo_url}/>
											</div>
											<div className="col-8">
												<div className="card-body">
													<div className="card-text">
														<div
															className="star-ratings"
															style={{ width: percentage}}
														>
															<div
																className="fill-ratings"
																style={{ width: percentage}}
															>
																<div>
																	<i className="fa fa-star fa-lg"/>
																	<i className="fa fa-star fa-lg"/>
																	<i className="fa fa-star fa-lg"/>
																	<i className="fa fa-star fa-lg"/>
																	<i className="fa fa-star fa-lg"/>
																</div>
															</div>
															<div
																className="empty-ratings"
																style={leftEmpty}
															>
																<div>
																	<i className="fa fa-star fa-lg"/>
																	<i className="fa fa-star fa-lg"/>
																	<i className="fa fa-star fa-lg"/>
																	<i className="fa fa-star fa-lg"/>
																	<i className="fa fa-star fa-lg"/>
																</div>
															</div>
														</div>
														<img 
														alt=""
														className="img-fluid"
														src={r.logo_url_restaurant}
														style={{
															// "position": "absolute",
															// "width": "50px",
															// "left": "0px",
															// "background":"white",
															// "top": "0px",
															// "border": "0px solid #dee2e6"

															"position": "absolute",
															"width": "65px",
															"right": "6px",
															"background":"white none repeat scroll 0% 0%",
															"top": "6px",
															"border": "1px solid  #dee2e6",
															"borderRadius": "50%",
															"padding": "5px",
														}}/>
														<small className="d-block">
															{puntuacion}
														</small>
														<small>votos: {r.total_votos}</small>
													</div>
													<h5 className="card-title">
														{r.name}
													</h5>
												</div>
											</div>
										</div>
									</Link>
									</div>
									/*<div className="col-12 px-1 mb-2" key={"r-"+i}>
										<Link to={"/burger/"+r.slug} className="h-100">									
											<div className="border mb-5 text-dark h-100 text-center" style={{position:"relative"}}>
												<img alt="" className="img-fluid w-75 py-5" src={r.logo_url}/>
												<img 
													alt=""
													className="img-fluid"
													src={r.logo_url_restaurant}
													style={{
														// "position": "absolute",
														// "width": "50px",
														// "left": "0px",
														// "background":"white",
														// "top": "0px",
														// "border": "0px solid #dee2e6"

														"position": "absolute",
														"width": "65px",
														"left": "6px",
														"background":"white none repeat scroll 0% 0%",
														"top": "6px",
														"border": "1px solid  #dee2e6",
														"border-radius": "50%",
														"padding": "5px",
													}}/>

												<small className="d-block">{puntuacion}</small>
												<div className="star-ratings"  style={{ width: percentage}}>
													<div className="fill-ratings" style={{ width: percentage}}>
														<div>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
														</div>
													</div>
													<div className="empty-ratings" style={leftEmpty}>
														<div>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
															<i className="fa fa-star fa-lg"/>
														</div>
													</div>
												</div>
												<small>votos: {r.total_votos}</small>
												<p className={"px-3 font-weight-bold res-id-"+r.id}>
													<small>{r.name}</small>
												</p>
											</div>
										</Link>
									</div>*/
								)
							})
						:
							<>
							{	loading===true ?
								<div className="col-md-4 text-center h4">
									{/* <img alt="" className="img-fluid" src={chop}/> */}
									<Start height="35px" className="start-vote"/>
									<br/>
									Buscando participantes..
								</div>
								: <h3 className="text-center"><br/><br/>Ups! no hay puntuaciones disponible para {country}</h3>
							}
							</>
					}
				</div>
				
			</>
		);
	}
}
export default TheTop;