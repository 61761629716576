import React from 'react';
import { Drawer,Button,Panel,Dropdown } from 'rsuite';
import { useHistory, Link } from 'react-router-dom';
import {
  Collapse,
	Navbar,
	NavbarToggler,
	Nav,
	NavItem,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem 
} from 'reactstrap';
import { GetMenuCountry, GetForCountry }from '../../../Services/Helpers';


import Session from '../../../Services/Session'

const NavMain = () => {
  return(
    <div className="row sticky-top border-bottom ">
      <Navbar color="white" expand="md" style={{zIndex:"1021"}} className="w-100">
        <MenuDrawer className="d-md-none"/>
        <Brand/>
        {<div style={{width:'50px'}}></div>}
        <NavDes/>
      </Navbar>
    </div>
  );
}

const MenuDrawer = (props) =>{
  const history = useHistory();
  const [size, setSize] = React.useState('xs');
  const [open, setOpen] = React.useState(false);
  const [openPanel, setOpenPanel] = React.useState(null);
  const [placement, setPlacement] = React.useState();
  const MenuLinks = GetMenuCountry();
  const handleOpen = key => {
    setOpen(true);
    setPlacement(key);
  };

  return(
    <div className={`${props.className}`}>
    <Button onClick={() => handleOpen('left')}>
    <i class="fas fa-bars"></i>
    </Button>
    <Drawer
      size={size}
      placement={placement}
      open={open}
      onClose={() => setOpen(false)}
      style={{
        width:'25rem',
      }}
    >
      <Drawer.Header>
        <Drawer.Title className="text-center">
          <Brand/>
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className="px-0">
        {
          Session.isAuth() ?
          null :
          <p className="text-center mb-3">
            Ingresar a tu cuenta para participar
          </p>
        }
        <div className="d-block text-center">
          {
            Session.isAuth() ?
            <Button color="red" appearance="ghost" className="px-4"
              onClick={()=>history.push(`/mi/perfil/${Session.getUserName()}`)}
            >
              MI PERFIL
            </Button>
            :
            <Button color="red" appearance="ghost" className="px-4"
              onClick={()=>history.push(`/sign/in`)}
            >
              ENTRAR A MI CUENTA
            </Button>
          }
          
          {
            Session.isAuth() ?
            <>
            <br/>
            <br/>
            <a className="pt-3"
              href="#"
              onClick={()=>{Session.close()}}
            >
              CERRAR SESIÓN
            </a>
            </>
            :null
           }
        </div>
        <hr className="w-100"/>
        <ul class="list-group list-group-flush">
          {
            MenuLinks.map((item,i)=>{
              const className = "list-group-item d-flex justify-content-between align-items-center pt-4 border-bottom-0 text-dark";
              const ItemView = () => {
                return(
                  <>
                    {item.name}
                    {item.sub.length>0 ? <span><i class="fas fa-chevron-right"></i></span>: null}
                  </>
                )
              }
              if (item.linkWp) {
                return(
                  <a href={item.linkWp} className={className}>
                    <ItemView/>
                  </a>
                )
              }
              return(
                <>
                {
                  item.sub.length>0 ?
                  <>
                    <a href={'#'} className={`pb-0 ${className}`} onClick={() => setOpenPanel(openPanel===i ? null : i)}>
                      <ItemView/>
                    </a>
                    <Panel collapsible  expanded={openPanel===i}>
                      {
                        item.sub.map((item1,i1)=>{
                          if (item1.linkWp) {
                            return(
                              <a href={item1.linkWp} className={"text-dark py-2 d-block"}>
                                {item1.name}
                              </a>
                            )
                          }
                          return(
                            <Link to={item1.link} onClick={() => setOpen(false)} className={"text-dark py-2 d-block"}>

                            </Link>
                          )
                        })
                      }
                    </Panel>
                  </>
                  :
                  <Link to={item.link} onClick={() => setOpen(false)} className={className}>
                    <ItemView/>
                  </Link>
                }
                </>
              )
            })
          }
        </ul>
      </Drawer.Body>
    </Drawer>
    </div>
  );
}

export const Brand = () => {
  return(<a className="navbar-brand" href={GetForCountry().mainDomain}>
    <img 
    alt="Brand"
    className="img-fluid header-logo"
    src={GetForCountry().brand}
    width="120"
    />
  </a>);
}

export default NavMain;

const NavDes = () => {
  const MenuLinks = GetMenuCountry();
  return(
    <div class="navbar-nav  d-none d-md-flex ml-auto">
      {
        MenuLinks.map((item,i)=>{
          const className="nav-item nav-link text-dark";
          if (item.sub.length>0) {
            return(
              <Dropdown renderToggle={(props,ref)=><a href="#" {...props} ref={ref} className={className}>{item.name}</a>} >
                {
                  item.sub.map((item1,i1)=>{
                    if (item1.linkWp) {
                      return(
                        <Dropdown.Item as={MyLinkA} href={item1.linkWp}>
                          {item1.name}
                        </Dropdown.Item>
                      )
                    }
                    return(
                      <Dropdown.Item as={MyLink} href={item1.link}>
                        {item1.name}
                      </Dropdown.Item>
                    )
                  })
                }
              </Dropdown>
            )
          }
          if (item.linkWp) {
            return(
              <a href={item.linkWp} className={className}>
                {item.name}
              </a>
            )
          }
          return(
            <Link to={item.link} onClick={() => null} className={className}>
              {item.name}
            </Link>
          );
        })
      }
    </div>
  )
}
const MyLinkA = React.forwardRef((props, ref) => {
  const { href, as, ...rest } = props;
  return (
    <a href={href} as={as}>
      <a ref={ref} {...rest} />
    </a>
  );
});
const MyLink = React.forwardRef((props, ref) => {
  const { href, as, ...rest } = props;
  return (
    <Link href={href} as={as}>
      <a ref={ref} {...rest} />
    </Link>
  );
});