import React, {Component} from 'react';
import { Link } from 'react-router-dom';
//import Session from '../Services/Session';
import Api from '../Services/Api';
import { GetCategoryUser }from '../Services/Helpers';
import  { ReactComponent as CategoriaIcon } from '../Layout/Assets/medalla.svg';
import  thetopJuezImg from '../Layout/Assets/thetop-juez.png';
import  termometro from '../Layout/Assets/termometro.png';
import  juezMesero from '../Layout/Assets/juez-mesero.png';

import  MasterGold from '../Layout/Assets/MasterGold.png';
import  Gold from '../Layout/Assets/Gold.png';
import  Silver from '../Layout/Assets/Silver.png';
import  Bronze from '../Layout/Assets/Bronze.png';

import  Patron from '../Layout/Assets/Patron.png';


//import Placeholder from '../Layout/Assets/placeholder.png';
import UserImg from '../Layout/Assets/user.png';
import chop from '../Layout/Assets/chop.gif';
import Paginate from '../Layout/Components/Paginate'

import {AvatarsList} from '../VoteHere/Avatars';

export default class Juez extends Component {
	constructor(props) {
		super(props)
		this.state = {
			info: {
				judges:0,
				votings:0,
				events:0,
				list_last_judges:[],
			},
			pagination:null,
			loading:true,
		}
		this.loadList = this.loadList.bind(this)
	}
	componentDidMount() {
		window.scrollTo(0,0)
		this.loadList()
	}
	async loadList(page={}){
		let response = await Api.theTopJudgeThermometer(page)
		this.setState({
			info:response,
			loading:false
		})
	}
	render() {
		let {info, pagination, loading} = this.state
		const TopJuezTermometro =() => {
			const list_last_judges=info.list_last_judges /*[
				{
					avatar:'MUJER1',
					name:'maria',
					total:135
				},
				{
					avatar:'HOMBRE1',
					name:'maria',
					total:235
				},
				{
					avatar:'HOMBRESILUETA',
					name:'maria',
					total:335
				},
				{
					avatar:'MUJERSILUETA',
					name:'maria',
					total:435
				},
				{
					avatar:'HOMBRE2',
					name:'maria',
					total:535
				},
				{
					avatar:'MUJER3',
					name:'maria',
					total:635
				}
			]*/
			let count=92;
			return(
				<>
					{list_last_judges.map((element,index)=>{
						//count=index==0 ? count : count+50;
						switch (index) {
							case 0:
								break;
							case 1:
								count=count+43;
								break;
							case 5:
								count=count+50;
								break;
							default:
								count=count+63;
								break;
						}
				 		return(
				 			<div className="row" style={{
				 					position:'absolute',
				 					top:`${count}px`,
				 					left: (window.innerWidth > 991 ? '71%' : '150px'),
				 					width:'180px',
				 					maxWidth:'180px'
				 			}}>
				 				<div className="col-2 px-1 py-2">
					 				<img
					 					class="img-fluid"
					 					src={AvatarsList[element.avatar]}
					 					src={
					 							element.user.avatar_selected==null ? 
					 								(element.user.avatar_id != null ? element.user.avatar_url : UserImg) :AvatarsList[element.user.avatar_selected]}
					 					style={{width: '90%',maxWidth: '90%',top:'-0.6rem',position:'inherit',}}
					 				/>
								</div>
								<div className="col-10 px-0">
					 				<p style={{lineHeight: '0.9'}} className="text-left mb-0">
									<span className="text-dark" style={{fontSize:'12px', fontWeight: 400}}>{element.user.name ?? element.user.username}</span>
									<br/>
									<span className="text-dark" style={{fontSize:'12px', fontWeight: 400}}>votos:{element.count_voting}</span>
									</p>
								</div>
							</div>
						)
				 	})}
				 </>
				)
		}
		return (
			<div className="row" style={{
				backgroundImage: 'url(' + Patron + ')',
				backgroundSize: '100%',
        overflow: 'hidden',
     	}}>
			<div className="col-12 py-3 mt-negative-4 mt-1 d-none d-lg-block">
				<div className="row">
					<div className="col-4 text-center pt-5 mt-5">
					<div className="row">
						<div className="col-12" style={{position:'relattive'}}>
							<img src={termometro} className="img-fluid" style={{/*width:'35%'*/ height: '424px'}}/>
							<TopJuezTermometro/>
						</div>
						<div className="col-12">

						<Link
							to="/todos/juez"
							className="btn py-2 my-5 custom-btn-red custom-btn"
						>
							Ver todos
						</Link>
						</div>
					</div>
					</div>
					<div className="col-4 text-center">
					<img src={thetopJuezImg} className="img-fluid"/>
						<p className="text-center m-auto pt-3">
							Revisa aquí los Sibaritas mas destacados y sus votos.
							<br/>
							Recuerda que mientras mas votes puedes 
							<br/>
							subir de categoría y acceder a los beneficios.
							<br/>
							<h2 className="py-3">
								CATEGORÍAS
							</h2>
						</p>
						<div className="row">
							<div className="col-3">
								<img src={MasterGold} className="img-fluid mb-3"/>
								MASTER GOLD
								<br/>
								<small>1</small>
							</div>
							<div className="col-3">
								<img src={Gold} className="img-fluid mb-3"/>
								GOLD
								<br/>
								<small>18</small>
							</div>
							<div className="col-3">
								<img src={Silver} className="img-fluid mb-3"/>
								SILVER
								<br/>
								<small>74</small>
							</div>
							<div className="col-3">
								<img src={Bronze} className="img-fluid mb-3"/>
								BRONZE
								<br/>
								<small>15538</small>
							</div>
						</div>
						<table class="table table-bordered bg-white" style={{
								border:' 6px solid rgb(228, 32, 47)',
								'-webkit-box-shadow': "10px 10px 0px -5px rgba(0,0,0,0.75)",
								'-moz-box-shadow': "10px 10px 0px -5px rgba(0,0,0,0.75)",
								'box-shadow': "10px 10px 0px -5px rgba(0,0,0,0.75)",
						}}>
						  <thead>
						    <tr>
						      <th scope="col" style={{border:' 6px solid rgb(228, 32, 47)'}}>JUECES</th>
						      <th scope="col" style={{border:' 6px solid rgb(228, 32, 47)'}}>EVENTO</th>
						      <th scope="col" style={{border:' 6px solid rgb(228, 32, 47)'}}>VOTOS</th>
						    </tr>
						  </thead>
						  <tbody>
						    <tr>
						      <td style={{border:' 6px solid rgb(228, 32, 47)'}}>{info.judges.cantidad}</td>
						      <td style={{border:' 6px solid rgb(228, 32, 47)'}}>{info.events.cantidad}</td>
						       <td style={{border:' 6px solid rgb(228, 32, 47)'}}>{info.votings.cantidad}</td>
						    </tr>
						  </tbody>
						</table>
					</div>
					<div className="col-4 text-center pt-5 mt-5">
					<img src={juezMesero} className="img-fluid" style={{width:'80%'}}/>
					</div>
				</div>
			</div>



			<div className="col-12 py-3 mt-negative-4 mt-1 d-block d-lg-none">
				<div className="row">
					<div className="col-12 text-center">
						<img src={thetopJuezImg} className="img-fluid"/>
						<p className="text-center m-auto pt-3">
							Revisa aquí los Sibaritas mas destacados y sus votos.
							<br/>
							Recuerda que mientras mas votes puedes 
							<br/>
							subir de categoría y acceder a los beneficios.
							<br/>
						</p>
					</div>
					<div className="col-6 text-center pt-5 mt-5 px-0">
						<div className="row">
							<div className="col-12 text-left">
							<img src={termometro} className="img-fluid" style={{/*width:'65%'*/ height: '424px'}}/>
							<TopJuezTermometro/>
							</div>
							<div className="col-12 text-center">

							<Link
								to="/todos/juez"
								className="btn py-2 my-5 custom-btn-red custom-btn"
							>
								Ver todos
							</Link>
							</div>
						</div>
					</div>
					<div className="col-6 text-center pt-5 mt-5 px-0">
						<br/>
						<br/>
						<br/>
						<br/>
						<img src={juezMesero} className="img-fluid" style={{width:'153px'}}/>
					</div>
					<div className="col-12 text-center">
						<div className="row">
							<div className="col-12 py-3">
							<h2 className="py-3">
								CATEGORÍAS
							</h2>
							</div>
							<div className="col-3">
								<img src={MasterGold} className="img-fluid mb-4"/>
								MASTER GOLD
								<br/>
								<small>1</small>
							</div>
							<div className="col-3">
								<img src={Gold} className="img-fluid mb-4"/>
								GOLD
								<br/>
								<small>18</small>
							</div>
							<div className="col-3">
								<img src={Silver} className="img-fluid mb-4"/>
								SILVER
								<br/>
								<small>74</small>
							</div>
							<div className="col-3">
								<img src={Bronze} className="img-fluid mb-4"/>
								BRONZE
								<br/>
								<small>15538</small>
							</div>
						</div>
						<table class="table table-bordered bg-white mt-3" style={{
								border:' 6px solid rgb(228, 32, 47)',
								'-webkit-box-shadow': "10px 10px 0px -5px rgba(0,0,0,0.75)",
								'-moz-box-shadow': "10px 10px 0px -5px rgba(0,0,0,0.75)",
								'box-shadow': "10px 10px 0px -5px rgba(0,0,0,0.75)",
							}}
						>
						  <thead>
						    <tr>
						      <th scope="col" style={{border:' 6px solid rgb(228, 32, 47)'}}>JUECES</th>
						      <th scope="col" style={{border:' 6px solid rgb(228, 32, 47)'}}>EVENTO</th>
						      <th scope="col" style={{border:' 6px solid rgb(228, 32, 47)'}}>VOTOS</th>
						    </tr>
						  </thead>
						  <tbody>
						    <tr>
						      <td style={{border:' 6px solid rgb(228, 32, 47)'}}>{info.judges.cantidad}</td>
						      <td style={{border:' 6px solid rgb(228, 32, 47)'}}>{info.events.cantidad}</td>
						      <td style={{border:' 6px solid rgb(228, 32, 47)'}}>{info.votings.cantidad}</td>
						    </tr>
						  </tbody>
						</table>
					</div>
				</div>
			</div>
			</div>
		);
	}
}