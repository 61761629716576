import React, {Component, useState, useEffect, useRef} from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import { DateTime } from 'react-datetime-bootstrap';
import Api from '../Services/Api';

const FormEvents = (props) => {
	const { event, loadList } = props
	const [data, setData] = useState({});
	const [form, setForm] = useState({});

	useEffect(() => {
		if(event!=null){
			setData(event)
			setForm(event)
		}
	},[props.event]);
	const selectActive = { selected: 'none'}

	const handleOnChnage = (e,info)  => {
		const name =  e.target ? e.target.name  : e;
		const value =  e.target ?
						 e.target.value  : info.value ? 
						 	info.value : info;
		setForm({
			...data,
			...form,
			[name]:value,
		});
		console.log(form)
	}
	const handleSubmit = async (e)  => {
		e.preventDefault();
		let response = {};
		if (form.id) {
			response = await Api.adminEventsUpdate(form.id,form);
			alert('Actualizado de forma exitosa!');
		}else{
			response = await Api.adminEventsCreate(form);
			alert('Creado de forma exitosa!');
		}
		loadList()
	}
	return(
		<Form onSubmit={ (e) => handleSubmit(e) } >
			<FormGroup >
				<Label for="eventName">Nombre</Label>
				<Input required type="text" id="eventName" name="name" placeholder="nombre del evento.." defaultValue={data.name} onChange={(e) => handleOnChnage(e)}/>
			</FormGroup>
			<Row form>
				<Col md={6}>
					<FormGroup>
						<Label for="eventStart">Inicio</Label>
						<DateTime 
							required id="eventStart" name="start"
							pickerOptions={{
								format:"YYYY-MM-DD HH:mm",
							}} 
							placeholder="fecha de inicio.."
							value={data.start}
							onChange={(e,date) => handleOnChnage('start',date)}
						/>
					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label for="eventEnd">Fin</Label>
						<DateTime 
							required id="eventEnd" name="end"
							pickerOptions={{
								format:"YYYY-MM-DD HH:mm",
							}} 
							placeholder="fecha de fin.."
							value={data.end}
							onChange={(e,date) => handleOnChnage('end',date)}
						/>
					</FormGroup>
				</Col>
			</Row>
			<FormGroup>
				<Label for="eventActive">Estado</Label>
				<Input required type="select" id="eventActive" name="active" value={form.active} onChange={(e) => handleOnChnage(e)}>
					<option value=''>Selecciona el estado</option>
					<option value={1}>Activo</option>
					<option value={0}>Inactivo</option>
				</Input>
			</FormGroup>
			<FormGroup>
				<Label for="eventType">Tipo</Label>
				<Input required type="select" id="eventType" name="type" value={form.type} onChange={(e) => handleOnChnage(e)}>
					<option value={1}>Zonas</option>
					<option value={2}>Categorías</option>
				</Input>
			</FormGroup>
			<FormGroup>
				<Label for="eventDescription">Descripción(Acepta tags html)</Label>
				<Input type="textarea" id="eventDescription" name="description" defaultValue={data.description} onChange={(e) => handleOnChnage(e)}/>
			</FormGroup>
			<Button>{data.id ? 'Actualizar' : 'Crear'}</Button>
		</Form>
	);
}
export default  FormEvents;
