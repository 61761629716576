import  React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import SignIn  from './SignIn';
import SignUp  from './SignUp';
import ResetPassword  from './ResetPassword';
import ActivateAccount  from './ActivateAccount';
import SentEmailChangePassword  from './SentEmailChangePassword';

class RouteAuth extends Component {
	constructor(props){
		super(props);
		this.state = {
			path: this.props.match.path,
		}
	}
	render(){
		var u=this.state.path
		return (
			<BrowserRouter>
				<Switch>
					<Route path={u+'activar/cuenta/:token'} render={(props) => <ActivateAccount {...props}/>}/>
					<Route path={u+'reset/:token'} render={(props) => <ResetPassword {...props}/>}/>
					<Route path={u+'sign/in'} component={SignIn}/>
					<Route path={u+'sign/up'} component={SignUp}/>
					<Route path={u+'enviar/mail'} component={SentEmailChangePassword}/>
					<Redirect from={u} to={u+'sign/in'}/>
				</Switch>
			</BrowserRouter>
		);
	}
}
export default RouteAuth;