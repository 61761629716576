import  React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Spinner } from 'reactstrap'

import Api from '../Services/Api'
import Session from '../Services/Session';
import FormProcessing from '../Services/FormProcessing'
import brand from '../Layout/Assets/logo_nav.png'
import { GetForCountry }from '../Services/Helpers';

class SignIn extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading:false,
			message:'',
			isAdmin:(props.profile=='admin')
		}
		this.form = React.createRef()
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	async handleSubmit(event){
		event.preventDefault();
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;
		this.setState({
			loading: true
		});
		let data = await FormProcessing.toObject(this.form.current)
		data.user_agent = userAgent;
		data.type_profile = this.state.isAdmin ? this.props.profile : 'juez' ;
		let validate = []
		let response = await Api.signIn(data);
		if(response.enable_validations===true){
			validate = response
		} 
		FormProcessing.validate(this.form.current,validate)
		if(response.error){
			this.setState({
				loading: false,
				message:response.error
			});
		}else if (response.token){
			Session.open(response)
			this.setState({
				loading: false,
			});
			window.location.href='/vota-aca';
		}else{
			this.setState({
				loading: false,
			});
		}
		
	}
	render(){
		const Logo = (props) => { return <img src={GetForCountry().brand} alt="sdad" className="brand" {...props}/>};
		return (
			<>
			<div className="sign-in container viewport-container h-100 d-flex align-items-center justify-content-center">
				<div className="col-10 col-sm-10 col-md-6 col-lg-3 px-2">
					<div className="text-center">
						{	this.state.isAdmin ?
							<div>
								<Logo style={{width: "36%"}}/>
								<h1>Administrador</h1>
							</div>
							: <Logo/>
						}
						
					</div>
					<form onSubmit={(e)=>this.handleSubmit(e)} ref={this.form}>
						<small id="emailHelp" className="form-text text-center text-danger mt-0 mb-3">
							{this.state.message}
						</small>
						<p className="text-center">¿Ya te haz registrado? ¡Inicia sesión!</p>
						<div className="form-group pb-3">
							<input 
								type="email"
								className="custom-input form-control"
								aria-describedby="emailHelp"
								placeholder="Email"
								name="email"
								id="email"
								required
							/>
						</div>
						<div className="form-group">
							<input 
								type="password"
								className="custom-input form-control"
								aria-describedby="password"
								placeholder="Contraseña"
								name="password"
								id="password"
								required
							/>
						</div>
						<button
							type="submit"
							className="btn btn-block py-3 custom-btn-red custom-btn"
							disabled={this.state.loading ? true : false}
						>
							{ 
								this.state.loading === false ? 'Inicia sesión' :
								<span>
								<Spinner style={{ 
									width: '1rem',
									height: '1rem',
									top: '-4px',
									position: 'relative'
								}}/> Iniciando..
								</span>

							}
						</button>
						<div className="form-group text-center py-2">
							<Link to="/sign/up" className="text-dark">
								Crear cuenta
							</Link>
						</div>
						<br/>
						<div className="form-group text-center">
							<Link to="/enviar/mail" className="text-dark">
								Olvidé mi Contraseña
							</Link>
						</div>
					</form>
				</div>
			</div>
			</>
		);
	}
}
export default SignIn;