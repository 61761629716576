import Fetch from './Fetch';

class Api {

  
  async filters(params={}) {
		let response = await Fetch.get('/filters/options', params);
		if (response) {
			return response;
		}
	}
	async signIn(params) {
		let response = await Fetch.post('/sign/in', params);
		if (response) {
			return response;
		}
	}
	async signUp(params) {
		let response = await Fetch.post('/sign/up', params);
		if (response) {
			return response;
		}
	}
	async checkAuth(params) {
		let response = await Fetch.get('/login/check', params);
		if (response) {
			return response;
		}
	}
	async resetEmailPass(params) {
		let response = await Fetch.put('/reset/pass/user', params);
		if (response) {
			return response;
		}
	}
	async changePass(params) {
		let response = await Fetch.post('/change/pass', params);
		if (response) {
			return response;
		}
	}
	async checkTokenReset(params) {
		let response = await Fetch.get('/check/token', params);
		if (response) {
			return response;
		}
	}
	async activateAccount(params) {
		let response = await Fetch.post('/activar/cuenta', params);
		if (response) {
			return response;
		}
	}
	
	/* user module */
		async listUsers(data){
			return await Fetch.get('/user/public/list',data)
		}
		async listUsersPrivate(data){
			return await Fetch.get('/user/private/list',data)
		}
		async createUser(data){
			return await Fetch.post('/user/store',data)
		}
		async profile(username,publicPath=false){
			return await Fetch.get( (publicPath ? '/public' : '')+'/user/'+username, {})
		}
    async getUsersListing(data={}){
			return await Fetch.get('/public/user/listing',data)
		}
    
		async followUser(username){
			return await Fetch.get('/follow/user/'+username,{})
		}
		async followUserList(url){
			return await Fetch.get(url,{})
		}
		async stopFollowing(username){
			return await Fetch.get('/stop/followin/user/'+username,{})
		}
		
		async showUser(username){
			return await Fetch.get('/user/show/private/'+username,{})
		}
		async updateUser(username,data){
			return await Fetch.put('/user/update/'+username,data)
		}
		async updateAvatar(username,data){
			return await Fetch.put('/user/avatar/'+username,data)
		}
		async changeStatusUser(data,id=null){
			return await Fetch.put('/user/change/status/'+id,data)
		}
		async changePassUser(id=null){
			return await Fetch.get('/user/reset/pass/'+id,{})
		}
		async deleteUser(id,data={}){
			return await Fetch.delete('/user/delete/'+id,data)
		}
	/* end user module */

	/* restaurnats module */
		async restaurants(data){
			return await Fetch.get('/restaurants',data)
		}
    async restaurantsSearch(data={}){
			return await Fetch.post('/restaurant/search',data)
		}
    
    async restaurantsListing(data){
			return await Fetch.get('/restaurants/listing',data)
		}
		async restaurant(slug,data){
			return await Fetch.get('/restaurant/'+slug,data)
		}
    async suppliers(slug,data){
			return await Fetch.get('/suppliers/'+slug,data)
		}
    
		async product(slug,data){
			return await Fetch.get('/product/'+slug,data)
		}

		async voteProduct(data){
      return await Fetch.post('/product/vote',data)
		}
    async product2(slug,data){
      return await Fetch.get('/product2/'+slug,data)
    }
		async theTop(data){
			return await Fetch.get('/the-top',data)
		}
		async theTopJudge(data){
			return await Fetch.get('/the-top/judge',data)
		}
		async theTopJudgeThermometer(data){
			return await Fetch.get('/the-top/judge/thermometer',data)
		}
    async theTopJudgeQualification(data={}){
			return await Fetch.get('/the-top/judge/qualification',data)
		}
    
    async serachUser(data={}){
			return await Fetch.get('/search/user',data)
		}
    
    
		async getVoting(id){
			return await Fetch.get('/voting/'+id,{})
		}
    async getVotingListing(data={}){
			return await Fetch.get('/voting/listing',data)
		}

		async getEventsCategory(id){
			return await Fetch.get('/events',{})
		}
    async getEventsListing(data={}){
			return await Fetch.get('/events/listing',data)
		}
    async getEventsListingR(data={}){
			return await Fetch.get('/events/listing/r',data)
		}
    
		async updateVoting(id,data){
			return await Fetch.put('/voting/'+id,data)
		}
		async deleteVoting(id){
			return await Fetch.delete('/voting/'+id,{})
		}
		
		
		
	/* end sms module */

	/* admin */
	
		async adminProducts(data){
			return await Fetch.get('/admin/products/list',data)
		}
		async adminProductsUpdate(id,data){
			return await Fetch.put('/admin/products/'+id,data)
		}
		async adminProductsCreate(data){
			return await Fetch.post('/admin/products/create',data)
		}
		async adminProductsDelete(id){
			return await Fetch.delete('/admin/products/delete/'+id,{})
		}

		async adminCategories(data){
			return await Fetch.get('/admin/categories/list',data)
		}
		async adminCategoriesUpdate(id,data){
			return await Fetch.put('/admin/categories/'+id,data)
		}
		async adminCategoriesCreate(data){
			return await Fetch.post('/admin/categories/create',data)
		}
		async adminCategoriesDelete(id){
			return await Fetch.delete('/admin/categories/delete/'+id,{})
		}

		async adminProvaiders(data){
			return await Fetch.get('/admin/providers/list',data)
		}
		async adminProvaidersUpdate(id,data){
			return await Fetch.put('/admin/provaiders/'+id,data)
		}
		async adminProvaidersCreate(data){
			return await Fetch.post('/admin/provaiders/create',data)
		}
		async adminProvaidersDelete(id){
			return await Fetch.delete('/admin/provaiders/delete/'+id,{})
		}

		async adminEvents(data){
			return await Fetch.get('/admin/events/list',data)
		}
		async adminEventsUpdate(id,data){
			return await Fetch.put('/admin/events/'+id,data)
		}
		async adminEventsCreate(data){
			return await Fetch.post('/admin/events/create',data)
		}
		async adminEventsDelete(id){
			return await Fetch.delete('/admin/events/delete/'+id,{})
		}

		async adminSelects(data){
			return await Fetch.get('/admin/selects',data)
		}

		async adminDashboard(data){
			return await Fetch.get('/admin/dashboard',data)
		}
		async listVoto(data){
			return await Fetch.get('/admin/list/vote',data)
		}

		
		
	/* end admmin module */
}

export default (new Api())