import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Api from '../Services/Api';
import FilterEvents from '../Components/Filters/FilterEvents'
import { Link } from 'react-router-dom';
import { Button, Rate } from 'rsuite';
import {GetNumericFloat} from '../Services/Helpers';
import HeartIcon from '@rsuite/icons/legacy/Heart';



const Suppliers = () => {
  let { slug } = useParams();
  let [ restaurant, setRestaurant ] = useState({
    products:[]
  });
  useEffect(()=>{
    const run = async () =>{
      let response = await Api.suppliers(slug)
      setRestaurant(response)
    }
    run()
  },[]);

  return(
    <>
      <FilterEvents
        addClass="mb-3 pt-5 sticky-top shop-filters-sticky"
        breadCrumbs={[
          {link:'/reviews',name:'reviews'},
          {link:`/proveedor/${slug}`,name:slug},
        ]}
      />
      <div className="row">
				<div className="col-12 col-md-12">
          <a href="#" onClick={(e)=> {e.preventDefault();window.history.back()}} className="text-dark">
            <i className="fas fa-arrow-left "/> volver
          </a>
					<div className="row pt-3">
						<div className="col-3 col-md-3 px-1">
							{ typeof restaurant === 'object' ?
								<>
									<img alt="" className="img-fluid border" src={restaurant.logo_url}/>
								</>
                :
                <h3 className="text-center">
                  Cargando foto...
                </h3>
							}
						</div>
            <div className="col-9 col-md-9 px-1">
              { typeof restaurant === 'object' ?
                /*<>
                  <p>
                    <span className="text-danger h3">
                      {restaurant.name}
                    </span>
                  </p>
                  <hr/>
                  <p>
                    <strong>Dirección:</strong> 
                    {restaurant.address}, {restaurant.commune.name}
                  </p>
                  <hr/>
                  <p>
                    <strong>Descripcion: </strong> 
                    {restaurant.description}
                  </p>
                </>*/
                <>
                <p>
                  <span className="text-danger h3">
                    {restaurant.name}
                  </span>
                  <hr/>
				{/* <br/>
					<Rate readOnly value={2.9} allowHalf size="xs"/> */}
				<br/>

                  <Rate value={restaurant.estadistica ? restaurant.estadistica[0] ? GetNumericFloat(restaurant.estadistica[0].rating_final) : 0 : 0} allowHalf readOnly size="xs"/>{' '}
                  <small className="small rate-item">{restaurant.estadistica ? restaurant.estadistica[0] ? GetNumericFloat(restaurant.estadistica[0].rating_final) : 0 : 0}</small>
				  <br/>
                  <span className="text-danger">reviews: {restaurant.estadistica ? restaurant.estadistica[0] ? restaurant.estadistica[0].total_votos : 0 : 0}</span>
                </p>
                </>
              :
              <h3 className="text-center">
                Cargando Detalles del restaurante...
              </h3>
              }
            </div>
            <div className="col-12 col-md-12">
				<div className={"row"}>
					<div className="col-12 px-1 pt-3">
						<h5>
							Productos
						</h5>
						<hr/>
					</div>
					{
						typeof restaurant.products === 'object' ? restaurant.products.map(function(p, i){
								return (
									<div className="col-12 col-md-12 col-lg-3 mb-4 px-1" key={'r-'+i}>
										<div className="card w-100 my-1">
											<Link to={"/producto/"+p.slug} className="card-img-top">
											<img alt={"img-"+p.slug}  src={p.logo_url} style={{
												maxHeight: "150px",
												objectFit: "cover",
												width: "100%",
											}} className="rounded-top"/>
											</Link>
											<div className="card-body px-2">
												<p className="small font-weight-bolder text-danger"
												style={{
													minHeight: "15px",
													display: '-webkit-box',
													overflow: 'hidden',
													webkitLineClamp: '1',
													webkitBoxOrient: 'vertical',
												}}
												>{p.name}</p>
												<Rate value={p.promedio_estrellas} allowHalf readOnly size="xs"/>{' '}
												<small className="small rate-item">{GetNumericFloat(p.promedio_estrellas)}</small>
												<br />
												<small className="small rate-item">reviews: {p.total_votos}</small>
												<br />
												<br />
												<Link to={"/producto/"+p.slug} className="btn btn-link p-0 text-danger">ver más</Link>
											</div>
										</div>
										{/*<div className="border mb-5 text-dark h-100">
											<img alt="" className="img-fluid" src={p.logo_url}/>
											<h5 className="text-center">
											{p.name} #{p.id}
											</h5>
											<p className="px-4 text-center">
												<small></small>
											</p>
										</div>*/}
									</div>
								)
							})
						:
							<>
								<div className="col-12">
									<h3 className="text-center py-5">
										:(
										Sin productos
									</h3>
								</div>
							</>
					}
				</div>
			</div>
          </div>
				</div>
		  </div>
    </>
  );
}

export default Suppliers;
