import React, {Component} from 'react';
import { Link , Redirect } from 'react-router-dom';
//import Session from '../Services/Session';
import Metropolitana from '../Layout/Assets/METROPOLITANA-01.png';
import Norte from '../Layout/Assets/NORTE-01.png';
import Sur from '../Layout/Assets/SUR-01.png';
import { GetForCountry }from '../Services/Helpers';
import Api from '../Services/Api';
import Session from '../Services/Session'


export default class Locations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			main: false,
			section: this.props.match.params.section,
			list:[
				{
					title:'Zona Norte',
					descrition:'Desde Iquique hasta Valparaiso',
					imagen:Norte,
					slug:'ZN',
				},
				{
					title:'Región Metropolitana',
					descrition:'',
					imagen:Metropolitana,
					slug:'MT',
				},
				{
					title:'Zona Sur',
					descrition:'Desde Talca a Puerto Montt',
					imagen:Sur,
					slug:'ZS',

				},
			],
			api:false,
			listapi:[],
			infolocal:false
		};
		this.loadList = this.loadList.bind(this)
	}
	componentDidMount() {
		window.scrollTo(0,0)
		this.loadList()
	}
	componentDidUpdate(propsDev) {
		let p = this.props
		if(
			p.match.params.section !== this.state.section
		){
			this.setState({
				section:p.match.params.section
			})
		}
	}
	async loadList(page={}){
		// alert('asdas')
		let response = await Api.getEventsCategory(page)
		if(typeof response==='object' && response[0] && (response[0].type !== null && response[0].type !== 1)){
			console.log(response)
			this.setState({
				api:true,
				listapi:response
			})
		}else{
			this.setState({
				infolocal:true
			})
		}
		return true
	}
	render() {
		const { section } = this.state
		const title = (typeof section === 'undefined' ? 'Participantes' : 'The Top')
		const element = (typeof section === 'undefined' ? 'restoranes' : 'the-top')
		if (GetForCountry().alfa2==='PE' && typeof section !== 'undefined' && Session.isAdmin() == false) {
			return (
				<Redirect to="/the-top/zona/lima" />
			)
		}
		if (GetForCountry().alfa2==='PE' && Session.isAdmin() == false) {
			return (
				<Redirect to="/restoranes/zona/lima" />
			)
		}
		if(this.state.api===true){
			return (
				<div className="row">
					<div className="bg-light col-12 py-5">
						<h3 className="text-center text-danger my-3 py-3">
							{title}
						</h3>
						<div className="row px-2">
							{this.state.listapi.map(function(item, i){
								return(
								<Link to={'/'+element+'/zona/'+item.category_slug} className="mb-5 col-md-12 col-lg-4 px-1 img-balck"  key={i+1}>
									<img
										src={item.category.banner_url}
										className="img-fluid w-100" alt="Imagen de Zona"
										style={{
											height:'12rem',
											borderRadius: '10px',
											objectFit: 'cover',
										}}
									/>
									<p className="text-center text-white p-img mb-0">
										<strong className="h3 mb-0"
											style={{
												// background:'#d62527',
												// padding:'0px 15px',
												// borderRadius: '2px'
											}}
										>
										{item.category_name}
										</strong>
									</p>
								</Link>
								)
							})}
						</div>
					</div>
				</div>
			)
		}
		if(this.state.infolocal===true){
			return (
				<div className="row">
				<div className="bg-light col-12 py-5 mt-negative-4">
				<h3 className="text-center text-danger my-3 py-4">
					Zonas
					<br/>
					{title}
				</h3>
				<div className="row px-2">
					{this.state.list.map(function(item, i){
						return(
							<Link to={'/'+element+'/zona/'+item.slug} className="mb-5 col-md-12 col-lg-4 px-1"  key={i+1}>
								<img src={item.imagen} className="img-fluid w-100" alt="Imagen de Zona"/>
								<p className="text-center text-white p-img">
									<strong className="h3">
									{item.title}
									</strong>
									<br/>
									{item.descrition}
								</p>
							</Link>
						)
					})}
				</div>
				</div>
				</div>
			);
		}
		return <><h1 className="text-center">Cargando..</h1></>;
	}
}