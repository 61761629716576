import React from 'react';
import WidgetSuppliers from '../Components/Widgets/WidgetSuppliers';
import WidgetReviews from '../Components/Widgets/WidgetReviews';
import WidgetTheTopJudges from '../Components/Widgets/WidgetTheTopJudges';
import WidgetCommunity from '../Components/Widgets/WidgetCommunity';

import MainQuantities from '../Components/Stats/MainQuantities';
import MainAutoCompleteJude from '../Components/Searchs/MainAutoCompleteJude'

import BannerjuezJpg from '../Layout/Assets/Bannerjuez.jpg'
import BannerjuezPng from '../Layout/Assets/benner_juez.png'

const TheTopJuez = () => {
  return(
    <>
    <div className="row text-white py-5 position-relative banner-juez align-items-center">
      <img src={BannerjuezPng} alt="" className="banner-juez-img"/>
      <div className="col-auto py-4">
        <p className="mb-0">THE TOP</p>
        <h1>JUECES</h1>
        <p className="text-right">SINCE 2019</p>
      </div>
    </div>
    <MainQuantities/>
    
    <div className="row py-5">
      <div className="col-12">
        <p className="text-center mb-3 title-line-bg">
          <span className="h4 text-dark">CONOCE A NUESTROS JUECES</span>
        </p>
        <MainAutoCompleteJude placeholder="BUSCA A TUS AMIGOS"/>
      </div>
    </div>
    <WidgetCommunity slider={true} amount={10}/>
    <WidgetTheTopJudges/>
    <WidgetReviews/>
    <WidgetSuppliers/>
    </>
  )
}

export default TheTopJuez