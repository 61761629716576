import  React, { Component } from 'react';
//import brand from '../Layout/Assets/logo_nav.png';
import { Link } from "react-router-dom";
//mport { Spinner } from 'reactstrap';
import SweetAlert from 'sweetalert2-react';

import  Api from '../Services/Api'
import Session from '../Services/Session';
//import FormProcessing from '../Services/FormProcessing'

class ActivateAccount extends Component {
	constructor(props) {
		super(props)
		this.state = {
			token: this.props.match.params.token,
			loading:true,
			error:'',
			showAlert:false,
			success:'',
			habilitado:false,
			redirect:false
		}
		this.form = React.createRef()
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	UNSAFE_componentWillMount() {
		window.scrollTo(0,0)
		this.checkToken(this.state.token)
	}
	async checkToken(token){
		this.handleSubmit();
	}
	async handleSubmit(){
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;
		this.setState({
			loading: true
		});
		let data={
			user_agent:userAgent,
			token:this.state.token
		}
		
		let response = await Api.activateAccount(data);
		if(response.error){
			this.setState({
				loading: false,
				error:response.error
			});
		}else if (response.token){
			Session.open(response)
			this.setState({
				loading: false,
				showAlert:true,
				habilitado:true,
				success:response.success
			});
		}else{
			this.setState({
				loading: false,
			});
		}
	}
	render(){
		const {loading,habilitado,redirect,showAlert,success} = this.state;
		if(redirect){
			window.location.href='/vota-aca';
		}
		return (
			<div className="container-fluid h-100">

				<SweetAlert
					show={showAlert}
					title="¡Buenísimo!"
					type="success"
					text={success}
					onConfirm={() => this.setState({ redirect: true })}
					confirmButtonText="Ir a votar"
				/>
				{
					habilitado===true ?
					<div className="row align-items-center justify-content-md-center h-50  text-center">
						<div className="col py-5">
						<h3>
						Cuenta activada!
						</h3>
						<br/>
						<Link to="/sign/in">Volver al inicio</Link>
						</div>
					</div>
					:
					(
						loading===true ?
						<div className="row align-items-center justify-content-md-center h-50 text-center">
							<div className="col py-5">
							<h3>
							Verificando..
							</h3>
							<br/>
							<Link to="/sign/in">Volver al inicio</Link>
							</div>
						</div>
						:
						<div className="row align-items-center justify-content-md-center h-50  text-center">
						<div className="col py-5">
						<h3>
						Link no disponible
						</h3>
						<br/>
						<Link to="/sign/in">Volver al inicio</Link>
						</div>
						</div>
					)
				}
			</div>
		);
	}
}
export default ActivateAccount;